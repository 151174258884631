.react-datepicker__tab-loop {
  z-index: 10;
}

.react-datepicker {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  background-color: white;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  padding-top: 16px;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  font-family: Arial, sans-serif;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 2.5rem;
  line-height: 2.5rem;
}

.react-datepicker__day {
  color: #111928;
  font-weight: 700;
  margin: 0.2rem;
}

.react-datepicker__day-name {
  color: #6b7280;
  font-weight: 600;
  margin: 0.2rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day:hover {
  background-color: #83b81a;
  color: #fff;
  border-radius: 8px;
}

.react-datepicker__navigation--previous {
  left: 1rem;
  padding-top: 16px;
}

.react-datepicker__navigation--next {
  right: 1rem;
  padding-top: 16px;
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  border-color: #111928;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.calendar-container {
  position: relative;
}

.calendar-buttons {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 16px;
  padding-right: 10px;

  gap: 16px;
}

.today-button,
.clear-button {
  padding: 8px 12px;
  background-color: #83b81a;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 50%;
  font-weight: 500;
}

.clear-button {
  background-color: white;
  color: #000;
}

.today-button:hover,
.clear-button:hover {
  opacity: 0.8;
}

.react-datepicker__view-calendar-icon {
  display: flex;
  align-items: center;
}

.react-datepicker__input-container input {
  padding: 8px 16px 8px 38px;
}

.react-datepicker__day--disabled {
  color: #e5e7eb;
}
.react-datepicker__day--disabled:hover {
  background-color: transparent;
  color: #e5e7eb;
}
