.roulette-pro-prize-list {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.roulette-pro-prize-list.horizontal {
  width: max-content;
}

.roulette-pro-prize-list.vertical {
  flex-direction: column;
  align-items: center;
}

.roulette-pro-prize-list.with-animation.horizontal {
  animation: roulette-horizontal-animation 100s linear infinite;
}

.roulette-pro-prize-list.with-animation.vertical {
  animation: roulette-vertical-animation 100s linear infinite;
}

@keyframes roulette-horizontal-animation {
  0% {
    /* transform: translateX(0); */
    /* left: 0; */
    transform: translate3d(0);
  }

  100% {
    /* transform: translateX(-3000px); */
    /* left: -3000px; */
    transform: translate3d(-3000px, 0, 0);
  }
}

@keyframes roulette-vertical-animation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translate3d(0, -3000px, 0);
  }
}
