@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.slick-dots {
  bottom: 16px !important;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}

.slick-next,
.slick-prev {
  z-index: 20;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
  color: #9ca3af !important;
  opacity: 1 !important;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide increment and decrement buttons in number input */
.no-spin::-webkit-outer-spin-button,
.no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spin {
  -moz-appearance: textfield;
}

.html-content img {
  margin-right: auto;
  margin-left: auto;
}

.html-content h6 {
  font-size: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.html-content ol {
  list-style-type: decimal;
}

.html-content li {
  margin-left: 36px;
  padding-left: 10px;
}

.html-content li:not(:last-child) {
  margin-bottom: 10px;
}

/* Giftaway */

.roulette-pro-regular-design-top {
  position: absolute;
  z-index: 10;
  background: #ff7524 linear-gradient(0deg, #ef3a28, #ff9305);
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.roulette-pro-regular-design-top.vertical {
  width: 100%;
  height: 4px;
}

/* Prize item */
.roulette-pro-regular-prize-item {
  text-align: center;
}

/* .roulette-pro-regular-prize-item:first-child .roulette-pro-regular-prize-item-wrapper {
  border-left: none;
} */

.roulette-pro-regular-prize-item-wrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.roulette-pro-regular-prize-item-text {
  box-sizing: border-box;
  display: block;
  padding: 16px;
  margin: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
  height: 60px;
  background: transparent !important;
  color: #111827 !important;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  white-space: break-spaces;
}

.roulette-pro-regular-design-prize-item-vertical
  .roulette-pro-regular-prize-item-wrapper {
  border-bottom: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
}

.html-content img {
  width: 100%;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}
